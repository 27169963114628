import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import Tab from '@emcasa/ui-dom/components/Tab';
import Text from '@emcasa/ui-dom/components/Text';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tab"
    }}>{`Tab`}</h1>
    <p>{`Tab component.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<Tab.Group>\n  <Tab label=\"A\">\n    <Text fontSize=\"large\">Tab A</Text>\n  </Tab>\n  <Tab label=\"B\">\n    <Text fontSize=\"large\">Tab B</Text>\n  </Tab>\n  <Tab label=\"C\">\n    <Text fontSize=\"large\">Tab C</Text>\n  </Tab>\n</Tab.Group>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      Tab,
      Text
    }} mdxType="Playground">
  <Tab.Group>
    <Tab label="A" mdxType="Tab"><Text fontSize="large" mdxType="Text">Tab A</Text></Tab>
    <Tab label="B" mdxType="Tab"><Text fontSize="large" mdxType="Text">Tab B</Text></Tab>
    <Tab label="C" mdxType="Tab"><Text fontSize="large" mdxType="Text">Tab C</Text></Tab>
  </Tab.Group>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      